import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const CalendarApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://function.plumhub.co.nz/api/",
  }),
  endpoints: (builder) => ({
    calendar: builder.query({
      query: (token) =>
        `getUserCalendar?code=ZpfPW1gzPJTmdMsGp6AzIycPOvgX3mPgsM2x6cF1bAivAzFuSbuHeg%3D%3D&token=${token}`,
    }),
  }),
})

export const { useCalendarQuery } = CalendarApi
