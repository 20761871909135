import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"

export const CalendarApi = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://localhost:3010/",
  }),
  endpoints: (builder) => ({
    calendar: builder.query({
      query: (code) => `/calendar?code=${code}`,
    }),
  }),
})

export const { useCalendarQuery } = CalendarApi
