import { useState } from "react"
//import "dotenv/config"
import Paper from "@mui/material/Paper"
import { useLocation } from "react-router-dom"
//@ts-ignore
import { ViewState } from "@devexpress/dx-react-scheduler"
import {
  Scheduler,
  Toolbar,
  MonthView,
  WeekView,
  ViewSwitcher,
  Appointments,
  AllDayPanel,
  DateNavigator,
  TodayButton,
  //@ts-ignore
} from "@devexpress/dx-react-scheduler-material-ui"
import { useCalendarQuery } from "../state/services/CalendarAzureFunction"
import ScaleLoader from "react-spinners/ScaleLoader"
//ts-ignore
import { AppointmentTooltip } from "../dx-react-scheduler-material-ui/src/plugins/appointment-tooltip"

export const Calendar = () => {
  const location = useLocation()

  const myParam = new URLSearchParams(location.search).get("code")

  const { data, isLoading } = useCalendarQuery(myParam)

  const [currentDate] = useState(Date.now())
  const [startDayHour] = useState(6)
  const [endDayHour] = useState(20)

  return (
    <Paper>
      {isLoading ? (
        <div
          style={{
            height: "500px",
            alignContent: "center",
            paddingTop: "300px",
            paddingLeft: "45%",
            maxHeight: "660px",
          }}
        >
          <ScaleLoader color="white" />
        </div>
      ) : !data ? (
        <div
          style={{
            height: "660px",
            alignContent: "center",
            paddingTop: "300px",
            paddingLeft: "45%",
            maxHeight: "660px",
          }}
        >
          Unauthorized
        </div>
      ) : (
        <Scheduler data={data} height={660} width={660}>
          <ViewState defaultCurrentDate={currentDate} />
          <WeekView
            startDayHour={startDayHour}
            endDayHour={endDayHour}
            excludedDays={[0, 6]}
            cellDuration={60}
          />
          <MonthView />
          <AllDayPanel />
          <Appointments />
          <AppointmentTooltip showOpenButton={false} showCloseButton />
          <Toolbar />
          <DateNavigator />
          <ViewSwitcher />
          <TodayButton />
        </Scheduler>
      )}
    </Paper>
  )
}
