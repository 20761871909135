import { configureStore } from "@reduxjs/toolkit"
import { CalendarApi } from "./state/services/Calendar"

const store = configureStore({
  reducer: {
    [CalendarApi.reducerPath]: CalendarApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(CalendarApi.middleware),
})

export default store
