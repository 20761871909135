import { ThemeProvider, createTheme } from "@mui/material/styles"
import { Calendar } from "./components/Scheduler"

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
})

function App() {
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <Calendar />
      </ThemeProvider>
    </>
  )
}

export default App
